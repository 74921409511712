<template>
  <div>
    <h2 v-t="'jobSettings'" class="text-2xl font-weight-semibold mb-4" />
    <v-card id="account-setting-card">
      <v-tabs v-model="currentTab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.icon">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ $t(tab.title) }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab" touchless>
        <v-tab-item>
          <job-settings-tab-sources />
        </v-tab-item>
        <v-tab-item>
          <job-settings-tab-reject-reasons />
        </v-tab-item>
        <v-tab-item>
          <job-settings-tab-custom-fields />
        </v-tab-item>
        <v-tab-item>
          <job-settings-tab-tags />
        </v-tab-item>
        <v-tab-item>
          <job-tab-gdpr />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiImport, mdiAccountCancelOutline, mdiTagOutline, mdiLockOutline, mdiBookEditOutline } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import JobSettingsTabSources from './job-settings-tab-sources/JobSettingsTabSources.vue'
import JobSettingsTabRejectReasons from './job-settings-tab-reject-reasons/JobSettingsTabRejectReasons.vue'
import JobSettingsTabCustomFields from './job-settings-tab-custom-fields/JobSettingsTabCustomFields.vue'
import JobSettingsTabTags from './job-settings-tab-tags/JobSettingsTabTags.vue'
import JobTabGdpr from './job-tab-gdpr/JobTabGdpr.vue'

export default {
  components: {
    JobSettingsTabSources,
    JobSettingsTabRejectReasons,
    JobSettingsTabCustomFields,
    JobSettingsTabTags,
    JobTabGdpr,
  },
  setup() {
    const currentTab = ref(null)
    const tabs = [
      { icon: mdiImport, title: 'sources' },
      { icon: mdiAccountCancelOutline, title: 'rejectReasons' },
      { icon: mdiBookEditOutline, title: 'customFields' },
      { icon: mdiTagOutline, title: 'tags' },
      { icon: mdiLockOutline, title: 'gdpr' },
    ]

    return {
      tabs,
      currentTab,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
