<template>
  <v-card flat class="pa-3 mt-2" :loading="cardLoading">
    <v-card-text>
      <v-form ref="rejectReasonsForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
        <v-row>
          <v-col cols="12">
            <masonry :cols="{ default: 3, 1620: 2, 1190: 1 }" :gutter="16">
              <div
                v-for="(rejectReasonCategory, index) in rejectReasonsCategories"
                :id="`rejectReasonCategoryCard_${index}`"
                :key="`${index}--category-reject-reason`"
              >
                <v-card outlined class="card--border-primary my-4">
                  <v-card-title>
                    <div v-if="!edit">{{ rejectReasonCategory.rejectCategory }}</div>
                    <v-text-field
                      v-else
                      v-model="rejectReasonCategory.rejectCategory"
                      outlined
                      dense
                      label="Reject category"
                      :rules="[validators.required]"
                      maxlength="160"
                      hide-details="auto"
                    >
                      <template #append-outer>
                        <v-tooltip top transition="scroll-y-transition" open-delay="150">
                          <template #activator="{ on, attrs }">
                            <v-btn
                              color="error"
                              x-small
                              v-bind="attrs"
                              @click="removeRejectReasonCategory(index)"
                              v-on="on"
                            >
                              <v-icon class="me-2" size="16">
                                {{ icons.mdiTrashCanOutline }}
                              </v-icon>
                              <span v-t="'delete'" />
                            </v-btn>
                          </template>
                          <span class="text-xs"> Delete category </span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-card-title>
                  <v-card-text>
                    <v-list-item
                      v-for="(rejectReason, rejectReasonIndex) in rejectReasonCategory.rejectReason"
                      :key="`${rejectReasonIndex} - ${rejectReasonCategory.id}--reject-reason`"
                    >
                      <v-list-item-content v-if="!edit">{{ rejectReason.rejectReason }}</v-list-item-content>
                      <v-list-item-content v-else
                        ><v-text-field
                          v-model="rejectReason.rejectReason"
                          outlined
                          dense
                          label="Reject reason"
                          :rules="[validators.required]"
                          maxlength="160"
                          hide-details="auto"
                        >
                          <template #append-outer>
                            <v-tooltip top transition="scroll-y-transition" open-delay="150">
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  color="error"
                                  x-small
                                  outlined
                                  v-bind="attrs"
                                  @click="removeRejectReason(index, rejectReasonIndex)"
                                  v-on="on"
                                >
                                  <v-icon class="me-2" size="16">
                                    {{ icons.mdiTrashCanOutline }}
                                  </v-icon>
                                  <span v-t="'delete'" />
                                </v-btn>
                              </template>
                              <span class="text-xs"> Delete reject reason </span>
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="edit">
                      <v-list-item-content>
                        <v-btn outlined color="primary" class="my-2" block @click="addRejectReason(index)"
                          >Add reject reason</v-btn
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </div>
              <v-btn v-if="edit" color="primary" class="my-4" block @click="addRejectReasonCategory"
                >Add reject reason category</v-btn
              >
            </masonry>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!edit" cols="12"
            ><v-btn color="primary" @click.prevent="edit = !edit">
              <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon>
              <span v-t="'edit'" /> </v-btn
          ></v-col>
          <v-col v-if="edit" cols="12">
            <v-btn color="primary" class="me-3" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
              {{ $t('save') }}
            </v-btn>
            <v-btn v-t="'discard'" outlined color="secondary" @click="restoreData" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'
import { mdiTrashCanOutline, mdiPencilOutline } from '@mdi/js'
import { ref, onMounted, computed, nextTick } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { useActions } from 'vuex-composition-helpers'

export default {
  setup() {
    const rejectReasonsForm = ref(null)
    const valid = ref(true)
    const loading = ref(false)
    const cardLoading = ref(false)
    const rejectReasonsCategories = ref([])
    let frozenRejectReasonsCategories = {}
    const edit = ref(false)

    const { getRejectReasonsCategories, updateRejectReasonsCategories } = useActions('jobs', [
      'getRejectReasonsCategories',
      'updateRejectReasonsCategories',
    ])

    onMounted(async () => {
      cardLoading.value = true
      rejectReasonsCategories.value = await getRejectReasonsCategories()
      frozenRejectReasonsCategories = Object.freeze(_.cloneDeep(rejectReasonsCategories.value))
      cardLoading.value = false
    })

    const handleFormSubmit = async () => {
      const isFormValid = rejectReasonsForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      rejectReasonsCategories.value = await updateRejectReasonsCategories(rejectReasonsCategories.value)
      frozenRejectReasonsCategories = Object.freeze(_.cloneDeep(rejectReasonsCategories.value))
      loading.value = false
      edit.value = false
    }

    const restoreData = () => {
      rejectReasonsCategories.value = _.cloneDeep(frozenRejectReasonsCategories)
      edit.value = false
    }

    const addRejectReasonCategory = async () => {
      rejectReasonsCategories.value.push({
        rejectCategory: '',
        rejectReason: [],
      })
      nextTick(() => {
        const el = document.getElementById(`rejectReasonCategoryCard_${rejectReasonsCategories.value.length - 1}`)
        el.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      })
    }

    const removeRejectReasonCategory = (index) => {
      rejectReasonsCategories.value.splice(index, 1)
    }

    const addRejectReason = (categoryIndex) => {
      const category = rejectReasonsCategories.value[categoryIndex]
      category.rejectReason.push({
        rejectCategory: category.id,
        rejectReason: '',
      })
    }

    const removeRejectReason = (categoryIndex, index) => {
      const category = rejectReasonsCategories.value[categoryIndex]
      category.rejectReason.splice(index, 1)
    }

    const areDataChanged = computed(() => {
      return (
        !_.isEmpty(_.differenceWith(rejectReasonsCategories.value, frozenRejectReasonsCategories, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(frozenRejectReasonsCategories, rejectReasonsCategories.value, _.isEqual))
      )
    })

    return {
      handleFormSubmit,
      restoreData,
      addRejectReasonCategory,
      removeRejectReasonCategory,
      addRejectReason,
      removeRejectReason,

      areDataChanged,

      validators: {
        required,
      },
      rejectReasonsForm,
      valid,
      loading,
      cardLoading,
      rejectReasonsCategories,
      edit,
      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
