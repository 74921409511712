<template>
  <v-card flat class="pa-3 mt-2" :loading="cardLoading">
    <v-card-text>
      <v-form
        ref="categoriesSourcesForm"
        v-model="valid"
        class="multi-col-validation"
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="12">
            <masonry :cols="{ default: 3, 1620: 2, 1190: 1 }" :gutter="16">
              <div v-for="(category, index) in categories" :id="`categoryCard_${index}`" :key="`${index}--category`">
                <v-card outlined class="my-4 card--border-primary">
                  <v-card-title>
                    <div v-if="!edit">{{ category.category }}</div>
                    <v-text-field
                      v-else
                      v-model="category.category"
                      outlined
                      dense
                      :label="$t('category')"
                      :placeholder="$t('category')"
                      :rules="[validators.required]"
                      maxlength="160"
                      hide-details="auto"
                    >
                      <template #append-outer>
                        <v-tooltip top transition="scroll-y-transition" open-delay="150">
                          <template #activator="{ on, attrs }">
                            <v-btn color="error" x-small v-bind="attrs" @click="removeCategory(index)" v-on="on">
                              <v-icon class="me-2" size="16">
                                {{ icons.mdiTrashCanOutline }}
                              </v-icon>
                              <span v-t="'delete'" />
                            </v-btn>
                          </template>
                          <span v-t="'delete'" class="text-xs" />
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-card-title>
                  <v-card-text>
                    <v-list-item
                      v-for="(source, sourceIndex) in category.source"
                      :key="`${sourceIndex} - ${category.id}--source`"
                    >
                      <v-list-item-content v-if="!edit">{{ source.source }}</v-list-item-content>
                      <v-list-item-content v-else
                        ><v-text-field
                          v-model="source.source"
                          outlined
                          dense
                          :label="$t('source')"
                          :placeholder="$t('source')"
                          :rules="[validators.required]"
                          maxlength="160"
                          hide-details="auto"
                        >
                          <template #append-outer>
                            <v-tooltip top transition="scroll-y-transition" open-delay="150">
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  color="error"
                                  x-small
                                  outlined
                                  v-bind="attrs"
                                  @click="removeSource(index, sourceIndex)"
                                  v-on="on"
                                >
                                  <v-icon class="me-2" size="16">
                                    {{ icons.mdiTrashCanOutline }}
                                  </v-icon>
                                  <span v-t="'delete'" />
                                </v-btn>
                              </template>
                              <span v-t="'delete'" class="text-xs" />
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="edit">
                      <v-list-item-content>
                        <v-btn
                          v-t="'addSource'"
                          outlined
                          color="primary"
                          class="my-2"
                          block
                          @click="addSource(index)"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </div>
              <v-btn v-if="edit" v-t="'addCategory'" color="primary" class="my-4" block @click="addCategory" />
            </masonry>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!edit" cols="12"
            ><v-btn color="primary" @click.prevent="edit = !edit">
              <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon> <span v-t="'edit'" /> </v-btn
          ></v-col>
          <v-col v-if="edit" cols="12">
            <v-btn color="primary" class="me-3" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
              {{ $t('save') }}
            </v-btn>
            <v-btn v-t="'discard'" outlined color="secondary" @click="restoreData" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'
import { mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js'
import { ref, onMounted, computed, nextTick } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { useActions } from 'vuex-composition-helpers'

export default {
  setup() {
    const categoriesSourcesForm = ref(null)
    const valid = ref(true)
    const loading = ref(false)
    const cardLoading = ref(false)
    const categories = ref([])
    let frozenCategories = {}
    const edit = ref(false)

    const { getApplyFormSourceCategories, updateApplyFormSourceCategories } = useActions('jobs', [
      'getApplyFormSourceCategories',
      'updateApplyFormSourceCategories',
    ])

    onMounted(async () => {
      cardLoading.value = true
      categories.value = await getApplyFormSourceCategories()
      frozenCategories = Object.freeze(_.cloneDeep(categories.value))
      cardLoading.value = false
    })

    const handleFormSubmit = async () => {
      const isFormValid = categoriesSourcesForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      categories.value = await updateApplyFormSourceCategories(categories.value)
      frozenCategories = Object.freeze(_.cloneDeep(categories.value))
      loading.value = false
      edit.value = false
    }

    const restoreData = () => {
      categories.value = _.cloneDeep(frozenCategories)
      edit.value = false
    }

    const addCategory = async () => {
      categories.value.push({
        category: '',
        source: [],
      })
      nextTick(() => {
        const el = document.getElementById(`categoryCard_${categories.value.length - 1}`)
        el.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      })
    }

    const removeCategory = (index) => {
      categories.value.splice(index, 1)
    }

    const addSource = (categoryIndex) => {
      const category = categories.value[categoryIndex]
      category.source.push({
        category: category.id,
        source: '',
      })
    }

    const removeSource = (categoryIndex, index) => {
      const category = categories.value[categoryIndex]
      category.source.splice(index, 1)
    }

    const areDataChanged = computed(() => {
      return (
        !_.isEmpty(_.differenceWith(categories.value, frozenCategories, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(frozenCategories, categories.value, _.isEqual))
      )
    })

    return {
      handleFormSubmit,
      restoreData,
      addCategory,
      removeCategory,
      addSource,
      removeSource,

      areDataChanged,

      validators: {
        required,
      },
      categoriesSourcesForm,
      valid,
      loading,
      cardLoading,
      categories,
      edit,
      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
