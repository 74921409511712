<template>
  <v-card flat class="pa-3 mt-2" :loading="cardLoading">
    <v-card-text>
      <v-form ref="tagsForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
        <v-row v-if="$can(ACTIONS.READ, PRIVATE.STAFF.EMPLOYEES)" no-gutters class="mb-n3">
          <v-col cols="12">
            <v-alert color="info" text class="mb-0">
              <p v-t="'sharedTagsJobsAlert'" class="font-weight-semibold" />
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <masonry :cols="{ default: 3, 1620: 2, 1190: 1 }" :gutter="16">
              <div v-for="(tag, index) in tags" :id="`categoryCard_${index}`" :key="`${index}--category`">
                <v-card outlined class="my-4 card--border-primary">
                  <v-card-title>
                    <div v-if="!edit">{{ tag.tag }}</div>
                    <v-text-field
                      v-else
                      v-model="tag.tag"
                      outlined
                      dense
                      :label="$t('tag')"
                      :placeholder="$t('tag')"
                      :rules="[validators.required]"
                      maxlength="50"
                      hide-details="auto"
                    >
                      <template #append-outer>
                        <v-tooltip top transition="scroll-y-transition" open-delay="150">
                          <template #activator="{ on, attrs }">
                            <v-btn color="error" x-small v-bind="attrs" @click="removeTag(index)" v-on="on">
                              <v-icon class="me-2" size="16">
                                {{ icons.mdiTrashCanOutline }}
                              </v-icon>
                              <span v-t="'delete'" />
                            </v-btn>
                          </template>
                          <span v-t="'delete'" class="text-xs" />
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-card-title>
                </v-card>
              </div>
              <v-btn v-if="edit" v-t="'addTag'" color="primary" class="my-4" block @click="addTag" />
            </masonry>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!edit" cols="12"
            ><v-btn color="primary" @click.prevent="edit = !edit">
              <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon> <span v-t="'edit'" /> </v-btn
          ></v-col>
          <v-col v-if="edit" cols="12">
            <v-btn color="primary" class="me-3" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
              {{ $t('save') }}
            </v-btn>
            <v-btn v-t="'discard'" outlined color="secondary" @click="restoreData" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'
import { mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js'
import { ref, onMounted, computed, nextTick } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { useActions } from 'vuex-composition-helpers'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'

export default {
  setup() {
    const tagsForm = ref(null)
    const valid = ref(true)
    const loading = ref(false)
    const cardLoading = ref(false)
    const tags = ref([])
    let frozenTags = {}
    const edit = ref(false)

    const { getCandidateTags, updateCandidateTags } = useActions('company', ['getCandidateTags', 'updateCandidateTags'])

    onMounted(async () => {
      cardLoading.value = true
      tags.value = await getCandidateTags()
      frozenTags = Object.freeze(_.cloneDeep(tags.value))
      cardLoading.value = false
    })

    const handleFormSubmit = async () => {
      const isFormValid = tagsForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      tags.value = await updateCandidateTags(tags.value)
      frozenTags = Object.freeze(_.cloneDeep(tags.value))

      loading.value = false
      edit.value = false
    }

    const restoreData = () => {
      tags.value = _.cloneDeep(frozenTags)
      edit.value = false
    }

    const addTag = async () => {
      tags.value.push({
        category: '',
        source: [],
      })
      nextTick(() => {
        const el = document.getElementById(`categoryCard_${tags.value.length - 1}`)
        el.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      })
    }

    const removeTag = (index) => {
      tags.value.splice(index, 1)
    }

    const areDataChanged = computed(() => {
      return (
        !_.isEmpty(_.differenceWith(tags.value, frozenTags, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(frozenTags, tags.value, _.isEqual))
      )
    })

    return {
      handleFormSubmit,
      restoreData,
      addTag,
      removeTag,

      areDataChanged,

      validators: {
        required,
      },
      tagsForm,
      valid,
      loading,
      cardLoading,
      tags,
      edit,
      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
      },
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
