<template>
  <v-card flat class="pa-4">
    <!-- change password -->
    <!--    <v-card class="mb-7 card&#45;&#45;border-primary" outlined>-->
    <v-card-title v-t="'consent'" />

    <v-card-text>
      <v-form
        ref="baseConsentForm"
        v-model="baseConsentValid"
        class="multi-col-validation"
        @submit.prevent="handleBaseConsentFormSubmit"
      >
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="baseConsentData.gdprConsent"
              outlined
              auto-grow
              :label="$t('consent')"
              :placeholder="$t('consent')"
              :rules="[validators.required]"
              rows="4"
              maxlength="5000"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-combobox
              v-model="baseConsentData.gdprRetentionJobInMonths"
              :items="monthItems"
              :hint="$t('personalDataProcessingPeriodHint')"
              persistent-hint
              outlined
              dense
              :label="$t('personalDataProcessingPeriod')"
              :placeholder="$t('personalDataProcessingPeriod')"
              :rules="[validators.required, validators.between(baseConsentData.gdprRetentionJobInMonths, 1, 24)]"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-combobox
              v-model="baseConsentData.gdprRetentionRequestAnonymizationInDays"
              :items="dayItems"
              :hint="$t('personalDataDeleteRetentionHint')"
              persistent-hint
              outlined
              dense
              :label="$t('personalDataDeleteRetention')"
              :placeholder="$t('personalDataDeleteRetention')"
              :rules="[
                validators.required,
                validators.between(baseConsentData.gdprRetentionRequestAnonymizationInDays, 1, 365),
              ]"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="!baseConsentValid || !areBaseConsentChanged"
              type="submit"
              class="me-3"
              :loading="baseConsentLoading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              v-t="'discard'"
              outlined
              color="secondary"
              :disabled="!areBaseConsentChanged"
              @click="restoreBaseConsent"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider class="my-3" />
    <v-card-title v-t="'talentPool'" />

    <v-card-text>
      <v-form
        ref="talentPoolConsentForm"
        v-model="talentPoolConsentValid"
        class="multi-col-validation"
        @submit.prevent="handleTalentPoolConsentFormSubmit"
      >
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="talentPoolConsentData.gdprTalentPoolConsent"
              outlined
              auto-grow
              :label="$t('consent')"
              :placeholder="$t('consent')"
              :rules="[validators.required]"
              rows="4"
              maxlength="5000"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              v-model="talentPoolConsentData.gdprTalentPoolRequired"
              hide-details="auto"
              class="mt-0"
              :label="$t('required')"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-combobox
              v-model="talentPoolConsentData.gdprRetentionTalentPoolInMonths"
              :items="monthItems"
              :hint="$t('talentPoolProcessingPeriodHint')"
              persistent-hint
              outlined
              dense
              :label="$t('talentPoolProcessingPeriod')"
              :placeholder="$t('talentPoolProcessingPeriod')"
              :rules="[
                validators.required,
                validators.between(talentPoolConsentData.gdprRetentionTalentPoolInMonths, 1, 24),
              ]"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              v-model="talentPoolConsentData.gdprSendEmailRenewConsentTalentPool"
              hide-details="auto"
              class="mt-0"
              :label="$t('talentPoolRenewEmail')"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="talentPoolConsentData.gdprSendEmailRenewConsentTalentPool">
          <v-col cols="12">
            <v-select
              v-model="talentPoolConsentData.gdprDefaultEmailRenewConsentTalentPool"
              :items="emailTemplates"
              :label="$t('talentPoolRenewEmailTemplate')"
              :placeholder="$t('talentPoolRenewEmailTemplate')"
              persistent-hint
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="!talentPoolConsentValid || !areTalentPoolConsentChanged"
              type="submit"
              class="me-3"
              :loading="talentPoolConsentLoading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              v-t="'discard'"
              outlined
              color="secondary"
              :disabled="!areTalentPoolConsentChanged"
              @click="restoreTalentPoolConsent"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider class="my-3" />
    <v-card-title v-t="'additionalConsents'" />

    <v-card-text v-if="!additionalConsentsEdit">
      <v-list>
        <v-list-item
          v-for="(additionalConsent, index) in additionalConsents"
          :key="`${index} - ${additionalConsent.id}`"
        >
          <v-list-item-content>
            <v-list-item-title>
              <v-row>
                <v-col cols="12" md="10" class="text-wrap" v-text="additionalConsent.title" />
                <v-col cols="12" lg="2">
                  <v-chip
                    label
                    small
                    color="primary"
                    :class="`v-chip-light-bg text-sm font-weight-semibold primary--text text-capitalize`"
                  >
                    {{ $t(additionalConsent.required ? 'required' : 'notRequired') }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-list-item-title>
            <v-list-item-content v-text="additionalConsent.consent" />
            <v-list-item-content>
              <v-divider />
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-row v-if="!additionalConsentsEdit">
        <v-col cols="12">
          <v-btn v-t="'edit'" color="primary" @click="additionalConsentsEdit = !additionalConsentsEdit" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <v-form
        ref="additionalConsentsForm"
        v-model="additionalConsentsValid"
        class="multi-col-validation"
        @submit.prevent="handleAdditionalConsentsFormSubmit"
      >
        <v-row
          v-for="(additionalConsent, index) in additionalConsents"
          :key="`${index} - ${additionalConsent.id}--edit`"
          class="d-flex align-center flex-column"
        >
          <v-col>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="additionalConsent.title"
                  outlined
                  dense
                  :label="$t('title')"
                  :placeholder="$t('title')"
                  maxlength="100"
                  :rules="[validators.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="additionalConsent.consent"
                  outlined
                  auto-grow
                  :label="$t('consent')"
                  :placeholder="$t('consent')"
                  :rules="[validators.required]"
                  rows="4"
                  maxlength="5000"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <v-switch
                  v-model="additionalConsent.required"
                  hide-details="auto"
                  class="mt-0"
                  :label="$t('required')"
                ></v-switch>
              </v-col>
              <v-col cols="2" class="d-flex justify-end align-center">
                <v-btn outlined color="error" x-small @click="deleteAdditionalConsent(index)">
                  <v-icon class="me-2" size="16">
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                  <span v-t="'delete'" />
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-divider class="my-4 w-full" />
        </v-row>
        <v-row>
          <v-col>
            <v-btn v-t="'add'" color="primary" block @click="addAdditionalConsent" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="!additionalConsentsValid || !areAdditionalConsentsChanged"
              type="submit"
              class="me-3"
              :loading="additionalConsentsLoading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn v-t="'discard'" outlined color="secondary" @click="restoreAdditionalConsents" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiSquareEditOutline, mdiDeleteOutline, mdiEyeOutline, mdiEyeOffOutline, mdiTrashCanOutline } from '@mdi/js'
import { ref, onMounted, computed } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required, between } from '@core/utils/validation'
import _ from 'lodash'

export default {
  setup() {
    const { getEmailTemplatesChoices } = useActions('jobs', ['getEmailTemplatesChoices'])
    const { updateCompanyGDPRSettings, getCompanyGDPRSettings, getAdditionalConsents, manageAdditionalConsents } =
      useActions('company', [
        'updateCompanyGDPRSettings',
        'getCompanyGDPRSettings',
        'getAdditionalConsents',
        'manageAdditionalConsents',
      ])

    const companyId = ref(null)
    const emailTemplates = ref([])

    const monthItems = ['1', '6', '12', '18', '24']
    const dayItems = ['1', '5', '7', '14', '30', '31']

    const baseConsentValid = ref(true)
    const baseConsentData = ref({})
    let frozenBaseConsentData = {}
    const baseConsentForm = ref(null)
    const baseConsentLoading = ref(false)

    const handleBaseConsentFormSubmit = async () => {
      const isFormValid = baseConsentForm.value.validate()
      if (!isFormValid) return
      baseConsentLoading.value = true
      const { data, success } = await updateCompanyGDPRSettings({ id: companyId.value, payload: baseConsentData.value })
      if (success) {
        baseConsentData.value = {
          gdprConsent: data.gdprConsent,
          gdprRetentionJobInMonths: data.gdprRetentionJobInMonths,
          gdprRetentionRequestAnonymizationInDays: data.gdprRetentionRequestAnonymizationInDays,
        }
        frozenBaseConsentData = _.cloneDeep(baseConsentData.value)
      }
      baseConsentLoading.value = false
    }
    const restoreBaseConsent = () => {
      baseConsentData.value = _.cloneDeep(frozenBaseConsentData)
    }
    const areBaseConsentChanged = computed(() => {
      return (
        baseConsentData.value.gdprConsent !== frozenBaseConsentData.gdprConsent ||
        baseConsentData.value.gdprRetentionJobInMonths !== frozenBaseConsentData.gdprRetentionJobInMonths ||
        baseConsentData.value.gdprRetentionRequestAnonymizationInDays !==
          frozenBaseConsentData.gdprRetentionRequestAnonymizationInDays
      )
    })

    const talentPoolConsentValid = ref(true)
    const talentPoolConsentData = ref({})
    let frozenTalentPoolConsentData = {}
    const talentPoolConsentForm = ref(null)
    const talentPoolConsentLoading = ref(false)

    const handleTalentPoolConsentFormSubmit = async () => {
      const isFormValid = talentPoolConsentForm.value.validate()
      if (!isFormValid) return
      talentPoolConsentLoading.value = true
      const { data, success } = await updateCompanyGDPRSettings({
        id: companyId.value,
        payload: talentPoolConsentData.value,
      })
      if (success) {
        talentPoolConsentData.value = {
          gdprRetentionTalentPoolInMonths: data.gdprRetentionTalentPoolInMonths,
          gdprSendEmailRenewConsentTalentPool: data.gdprSendEmailRenewConsentTalentPool,
          gdprTalentPoolConsent: data.gdprTalentPoolConsent,
          gdprTalentPoolRequired: data.gdprTalentPoolRequired,
          gdprDefaultEmailRenewConsentTalentPool: data.gdprDefaultEmailRenewConsentTalentPool,
        }
        frozenTalentPoolConsentData = _.cloneDeep(talentPoolConsentData.value)
      }
      talentPoolConsentLoading.value = false
    }
    const restoreTalentPoolConsent = () => {
      talentPoolConsentData.value = _.cloneDeep(frozenTalentPoolConsentData)
    }
    const areTalentPoolConsentChanged = computed(() => {
      return (
        talentPoolConsentData.value.gdprRetentionTalentPoolInMonths !==
          frozenTalentPoolConsentData.gdprRetentionTalentPoolInMonths ||
        talentPoolConsentData.value.gdprSendEmailRenewConsentTalentPool !==
          frozenTalentPoolConsentData.gdprSendEmailRenewConsentTalentPool ||
        talentPoolConsentData.value.gdprTalentPoolConsent !== frozenTalentPoolConsentData.gdprTalentPoolConsent ||
        talentPoolConsentData.value.gdprTalentPoolConsent !== frozenTalentPoolConsentData.gdprTalentPoolConsent ||
        talentPoolConsentData.value.gdprTalentPoolRequired !== frozenTalentPoolConsentData.gdprTalentPoolRequired ||
        talentPoolConsentData.value.gdprDefaultEmailRenewConsentTalentPool !==
          frozenTalentPoolConsentData.gdprDefaultEmailRenewConsentTalentPool
      )
    })

    const additionalConsentsForm = ref(null)
    const additionalConsentsValid = ref(true)
    const additionalConsents = ref([])
    let frozenAdditionalConsents = []
    const additionalConsentsLoading = ref(false)
    const additionalConsentsEdit = ref(false)

    const handleAdditionalConsentsFormSubmit = async () => {
      const isFormValid = additionalConsentsForm.value.validate()
      if (!isFormValid) return
      additionalConsentsLoading.value = true
      const { data, success } = await manageAdditionalConsents({
        companyId: companyId.value,
        payload: additionalConsents.value,
      })
      additionalConsents.value = data.additionalConsents
      frozenAdditionalConsents = Object.freeze(_.cloneDeep(additionalConsents.value))
      if (success) additionalConsentsEdit.value = false
      additionalConsentsLoading.value = false
    }
    const restoreAdditionalConsents = () => {
      additionalConsents.value = _.cloneDeep(frozenAdditionalConsents)
      additionalConsentsEdit.value = false
    }
    const areAdditionalConsentsChanged = computed(() => {
      return (
        !_.isEmpty(_.differenceWith(additionalConsents.value, frozenAdditionalConsents, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(frozenAdditionalConsents, additionalConsents.value, _.isEqual))
      )
    })
    const addAdditionalConsent = () => {
      additionalConsents.value.push({
        title: '',
        consent: '',
        required: false,
      })
    }
    const deleteAdditionalConsent = (index) => {
      additionalConsents.value.splice(index, 1)
    }

    onMounted(async () => {
      const { id, ...gdprSettings } = await getCompanyGDPRSettings()
      companyId.value = id
      baseConsentData.value = {
        gdprConsent: gdprSettings.gdprConsent,
        gdprRetentionJobInMonths: gdprSettings.gdprRetentionJobInMonths,
        gdprRetentionRequestAnonymizationInDays: gdprSettings.gdprRetentionRequestAnonymizationInDays,
      }
      talentPoolConsentData.value = {
        gdprRetentionTalentPoolInMonths: gdprSettings.gdprRetentionTalentPoolInMonths,
        gdprSendEmailRenewConsentTalentPool: gdprSettings.gdprSendEmailRenewConsentTalentPool,
        gdprTalentPoolConsent: gdprSettings.gdprTalentPoolConsent,
        gdprTalentPoolRequired: gdprSettings.gdprTalentPoolRequired,
        gdprDefaultEmailRenewConsentTalentPool: gdprSettings.gdprDefaultEmailRenewConsentTalentPool,
      }
      frozenBaseConsentData = Object.freeze(_.cloneDeep(baseConsentData.value))
      frozenTalentPoolConsentData = Object.freeze(_.cloneDeep(talentPoolConsentData.value))

      emailTemplates.value = await getEmailTemplatesChoices({
        actionType: 'renew_tp_consent',
      })

      additionalConsents.value = await getAdditionalConsents()
      frozenAdditionalConsents = Object.freeze(_.cloneDeep(additionalConsents.value))
    })

    return {
      handleBaseConsentFormSubmit,
      restoreBaseConsent,
      handleTalentPoolConsentFormSubmit,
      restoreTalentPoolConsent,
      handleAdditionalConsentsFormSubmit,
      restoreAdditionalConsents,
      addAdditionalConsent,
      deleteAdditionalConsent,

      areBaseConsentChanged,
      areTalentPoolConsentChanged,
      areAdditionalConsentsChanged,

      validators: {
        required,
        between,
      },
      monthItems,
      dayItems,
      emailTemplates,
      baseConsentValid,
      baseConsentData,
      baseConsentForm,
      baseConsentLoading,
      talentPoolConsentValid,
      talentPoolConsentData,
      talentPoolConsentForm,
      talentPoolConsentLoading,
      additionalConsentsForm,
      additionalConsentsValid,
      additionalConsents,
      additionalConsentsLoading,
      additionalConsentsEdit,
      icons: {
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>
